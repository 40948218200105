<template>
  <div>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        :aria-label="t('Buscar por nombre de país')"
        :placeholder="t('Buscar por nombre de país')"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
        v-model="searchQuery"
        @keyup="handleSearch"
      >
      <!-- v-model="searchQuery" -->
        <!-- @keyup="handleSearch" -->
      </v-text-field>

    <v-spacer></v-spacer>

    </v-card-text>
      <!-- table -->
    <div class="mx-10">
      <v-data-table
        :no-data-text="getError ? getError : $t('No hay datos disponibles.')"
        :headers="tableColumns"
        :items="countryListTable"
        :page="current_page"
        :server-items-length="totalCountryListTable"
        :loading="loading"
        @update:page="changingCountriesPage($event)"
        @update:items-per-page="changingCountriesPerPage($event)"
        @update:sort-desc="sortingPage($event)"
        :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              100
            ],
            'items-per-page-text': `${$t('Items por página')}`,
            'page-text': `{0}-{1} ${$t('de')} {2}`,
          }"
      >

      <!-- name -->
      <template #[`item.name`]="{item}">
        <v-row class="">
          <v-col cols="1" class="text-left">
            <span class="font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
          </v-col>
        </v-row>
      </template>

      <template #[`item.report`]="{item}">
        <v-btn 
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="$refs.reports_modal.openModal(item, true)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Ver')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      </v-data-table>
    </div>

  </v-card>
  <ReportsModal ref="reports_modal" />
  </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import { ref , watch } from '@vue/composition-api';
import { useUtils } from '@core/libs/i18n'
import countriesParserMixin from '@/utils/countriesParserMixin';
import debounce from '../../plugins/debounce.js'
import ReportsModal from './ReportsModal.vue'

import {
mdiDeleteOutline,
mdiSquareEditOutline,
mdiDotsVertical,
mdiPencilOutline,
mdiEyeOutline
} from '@mdi/js'


export default {
components: {
  ReportsModal,
}, 
data: ()  => {
  return {
    current_page: 1,
    page_size: 10,
    sortDesc: false,
    getError:null,
    loading: false,
  }
},
mixins: [countriesParserMixin],
methods: {
  async getCountryList() {
    this.loading = true;
    try {

      // Obtener la lista completa de países sin aplicar paginación
      const query = {
        search: this.searchQuery,
      };
      const response = await this.$api.getCountryList(query);

      //obtener nombre y traducir
      const translatedCountries = response.results.map(country => {
        return { id: country.id, name: this.countryParser[country.name] };
      });

      // ordenar
      translatedCountries.sort((a, b) => {
        if (this.sortDesc) {
          // Ordenar Z-A
          return b.name.localeCompare(a.name);
        } else {
          return a.name.localeCompare(b.name);
        }
      });

      // Guarda la lista completa ordenada en una propiedad separada
      this.allCountryList = translatedCountries;

      // Actualiza la lista paginada basada en la página actual
      const startIndex = (this.current_page - 1) * this.page_size;
      const endIndex = startIndex + this.page_size;
      this.countryListTable = this.allCountryList.slice(startIndex, endIndex);

      this.totalCountryListTable = response.count;
    } catch (error) {
      if (error?.response?.status === 403) {
        this.getError = error?.response?.data?.detail;
      }
    } finally {
      this.loading = false;
    }
  },

  changingCountriesPage(page) {
    this.current_page = page;
    // Actualiza la lista paginada cuando cambia la página
    const startIndex = (this.current_page - 1) * this.page_size;
    const endIndex = startIndex + this.page_size;
    this.countryListTable = this.allCountryList.slice(startIndex, endIndex);
  },
  // changingCountriesPage(e) {
  //   this.current_page = e
  //   this.getCountryList()
  // },
  async changingCountriesPerPage(e) {
    this.page_size = e;
    // Actualiza la lista paginada basada en la página actual
    const startIndex = (this.current_page - 1) * this.page_size;
    const endIndex = startIndex + this.page_size;
    this.countryListTable = this.allCountryList.slice(startIndex, endIndex);

  },

  sortingPage(e) {
    this.sortDesc = e
    this.getCountryList()
  },

  handleSearch: debounce(function() {
    this.current_page = 1;
    this.getCountryList();
  }, 400),
},
mounted() {
  this.getCountryList()
},
computed: {
  languaje() { return this.$i18n.locale},
  tableColumns() {
    return [
      { text: this.$t('Nombre'), value: 'name', align: 'left'},
      { text: this.$t('Reportes'), value: 'report', align: 'left'},
    ];
  },
},
watch: {
  languaje(newVal){
    // volver a traducir la lista cuando cambia el lenguaje
    if(newVal) this.getCountryList()
  }
},
setup() {
  const totalCountryListTable = ref(0);
  const countryListTable = ref([]);
  const searchQuery = ref('');
  const { t } = useUtils()
  return {
    totalCountryListTable,
    countryListTable,
    searchQuery,
    t,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiEyeOutline
    },
  }
},
}
</script>

<style scoped>
.no-uppercase {
text-transform: unset !important;
}
</style>
