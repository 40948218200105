import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"aria-label":_vm.t('Buscar por nombre de país'),"placeholder":_vm.t('Buscar por nombre de país'),"outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer)],1),_c('div',{staticClass:"mx-10"},[_c(VDataTable,{attrs:{"no-data-text":_vm.getError ? _vm.getError : _vm.$t('No hay datos disponibles.'),"headers":_vm.tableColumns,"items":_vm.countryListTable,"page":_vm.current_page,"server-items-length":_vm.totalCountryListTable,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': ("" + (_vm.$t('Items por página'))),
          'page-text': ("{0}-{1} " + (_vm.$t('de')) + " {2}"),
        }},on:{"update:page":function($event){return _vm.changingCountriesPage($event)},"update:items-per-page":function($event){return _vm.changingCountriesPerPage($event)},"update:sort-desc":function($event){return _vm.sortingPage($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c(VRow,{},[_c(VCol,{staticClass:"text-left",attrs:{"cols":"1"}},[_c('span',{staticClass:"font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.report",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"me-2 icon-with-label",attrs:{"color":"alt-primary","text":""},on:{"click":function($event){return _vm.$refs.reports_modal.openModal(item, true)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Ver'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)])]}}],null,true)})],1)],1),_c('ReportsModal',{ref:"reports_modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }