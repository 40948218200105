<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>{{t('Paises')}}</v-card-title>
        <countries-list />
      </v-card>
  
    </div>
  </template>
  
  <script>
import CountriesList from '@/components/Countries/CountriesList'
import { useUtils } from '@core/libs/i18n'

  export default {
   components: {
    CountriesList
},
   setup() {
    const { t } = useUtils()
      return {
        t,
        // icons
        icons: {
        },
      }
    },
  
  }
  </script>